import React from "react"

import {
  ColorMode,
  Box,
  Wrapper,
  H2,
  Text,
  Button,
  PrismicLink,
} from "components"

const CTA = ({
  call_to_action_title,
  subtitle,
  button_link,
  button_text,
  bg,
}) => (
  <ColorMode mode="dark">
    <Wrapper as="section" pt={[4, 5]} bg={bg || "brand.blue.0"} flush>
      <Box py={[5, null, 6]} px={[3, 4, 5, 6]}>
        {call_to_action_title && call_to_action_title.text && (
          <H2 children={call_to_action_title.text} />
        )}
        {subtitle && <Text children={subtitle} mt={3} />}
        {button_link && button_link.url && button_text && (
          <Box mt={4}>
            <PrismicLink link={button_link}>
              <Button children={button_text} variant="reverse" />
            </PrismicLink>
          </Box>
        )}
      </Box>
    </Wrapper>
  </ColorMode>
)

export default CTA
