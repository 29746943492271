import React from "react"

import { Wrapper, RichText, Box } from "components"

function Copy({ copy }) {
  return (
    <>
      <Wrapper as="section" id="community-description" pt={[4, 5]}>
        <Box width="100%" maxWidth={800} mx="auto">
          {copy && <RichText content={copy} fontSize={[1, 2, 3]} />}
        </Box>
      </Wrapper>
    </>
  )
}

export default Copy
