import React from "react"
import { graphql } from "gatsby"

import { SEO } from "components"
import { usePreviewData } from "utils"

import Header from "./_header"
import Copy from "./_copy"
import Image from "./_image"
import CTA from "./_cta"

function PostPage({ data }) {
  // Merge preview data with static query if possible
  const MERGED_DATA = usePreviewData(data)
  return (
    <>
      <SEO
        title={MERGED_DATA.prismicPost.data.title}
        description={MERGED_DATA.prismicPost.data.excerpt}
        image={MERGED_DATA.prismicPost.data.image}
      />
      <Header
        community={
          MERGED_DATA.prismicPost.data.community &&
          MERGED_DATA.prismicPost.data.community.uid
        }
        tag={MERGED_DATA.prismicPost.data.tag}
        title={MERGED_DATA.prismicPost.data.title}
        subtitle={MERGED_DATA.prismicPost.data.excerpt}
        image={MERGED_DATA.prismicPost.data.image}
      />
      {MERGED_DATA.prismicPost.data.body &&
        MERGED_DATA.prismicPost.data.body.map((post, index) => {
          switch (post.__typename) {
            case "PrismicPostBodyText":
              return <Copy {...post.primary} key={post + "slice" + index} />
            case "PrismicPostBodyCallToAction":
              return <CTA {...post.primary} key={post + "slice" + index} />
            case "PrismicPostBodyImage":
              return <Image {...post.primary} key={post + "slice" + index} />
            default:
              return null
          }
        })}
    </>
  )
}

export const query = graphql`
  query PostQuery($uid: String!) {
    prismicPost(uid: { eq: $uid }) {
      prismicId
      uid
      data {
        title {
          text
        }
        date
        excerpt
        featured
        tag
        community {
          uid
        }
        image {
          url
          alt

          fluid(maxWidth: 1600) {
            ...GatsbyPrismicImageFluid
          }
        }
        body {
          __typename
          ... on PrismicPostBodyCallToAction {
            id
            primary {
              button_link {
                link_type
                url
              }
              button_text
              subtitle
              call_to_action_title {
                text
              }
            }
          }
          ... on PrismicPostBodyImage {
            id
            primary {
              slice_image {
                url
                alt

                fluid(maxWidth: 1600) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicPostBodyText {
            id
            primary {
              copy {
                html
              }
            }
          }
        }
      }
    }
  }
`

export default PostPage
