import React, { useEffect } from "react"
import { Link } from "gatsby"
import css from "@styled-system/css"

import { useHeader } from "contexts/header"

import {
  AspectRatio,
  Flex,
  Box,
  H2,
  H4,
  H6,
  Image,
  Text,
  Wrapper,
} from "components"

const Header = ({ image, tag, community, title, subtitle }) => {
  const { setHeaderMode } = useHeader()

  useEffect(() => {
    if (image && image.url) {
      setHeaderMode("transparent")
    } else {
      setHeaderMode("solid")
    }
  }, [])
  return (
    <>
      {image && image.url && (
        <>
          <Box position="relative" display={["none", "block"]}>
            <AspectRatio ratio="2.2:1">
              <Image
                src={image}
                alt={image.alt}
                loading="eager"
                css={css({
                  position: "absolute !important",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  userSelect: "none",
                  pointerEvents: "none",
                })}
              />
            </AspectRatio>
          </Box>
          <Box position="relative" display={["block", "none"]}>
            <AspectRatio ratio="1.5:1">
              <Image
                src={image}
                alt={image.alt}
                loading="eager"
                css={css({
                  position: "absolute !important",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  userSelect: "none",
                  pointerEvents: "none",
                })}
              />
            </AspectRatio>
          </Box>
        </>
      )}
      <Wrapper as="section" pt={image && image.url ? [4, 5] : [128, 192]}>
        <Box width="100%" maxWidth={800} mx="auto">
          {title && <H2 children={title.text ? title.text : title} mb={3} />}
          {subtitle && <H4 children={subtitle} />}
          {(tag || community) && (
            <Flex mt={3}>
              <Text mr={1}>Tagged:</Text>
              {tag && (
                <Box
                  as={Link}
                  to={"/news/?filter=" + tag}
                  bg="brand.slate.0"
                  borderRadius={4}
                  p={1}
                  mr={1}
                >
                  <H6 color="background" mt={"1px"}>
                    {tag}
                  </H6>
                </Box>
              )}
              {community && (
                <Box
                  as={Link}
                  to={"/news/?community=" + community}
                  bg="brand.ochre.0"
                  borderRadius={4}
                  p={1}
                >
                  <H6 color="background" mt={"1px"}>
                    {community}
                  </H6>
                </Box>
              )}
            </Flex>
          )}
          <Box width={[16, 32]} height="1px" mt={[3, 4]} bg="border" />
        </Box>
      </Wrapper>
    </>
  )
}

export default Header
