import React from "react"

import { Wrapper, Image } from "components"

function ImageBlock({ slice_image }) {
  return (
    <Wrapper as="section" pt={[4, 5]}>
      {slice_image && <Image src={slice_image} />}
    </Wrapper>
  )
}

export default ImageBlock
